export const CustomInput = ({
  value,
  onChange,
}: {
  value: string | number;
  onChange: (arg: string) => void;
}) => {
  return (
    <input
      className="flex w-[260px] px-4 py-3 items-center gap-2.5 flex-shrink-0 
            rounded-lg border-2 border-gray-500 bg-black gap-auto text-white"
      type="number"
      inputMode="decimal"
      pattern="[0-9]*[.,]?[0-9]*"
      min="0"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter Ton"
    />
  );
};
