import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { RoundDetails } from "@/types/RoundDetails";
import { useEffect } from "react";
import useSWR, { type KeyedMutator } from "swr";

export interface RoundResponse {
  round: RoundDetails;
}

interface UseSoccerRoundReturn {
  data?: { round: RoundDetails };
  isLoading: boolean;
  mutate: KeyedMutator<RoundResponse>;
}

const fetcher = (url: string) => romanApi.get<RoundResponse>(url);

export const useSoccerRound = (
  roundId: string | undefined,
  handleError: (err: RomanApiError) => void,
): UseSoccerRoundReturn => {
  const { data, error, isValidating, mutate } = useSWR<RoundResponse, RomanApiError>(
    roundId ? `/soccer/rounds/${roundId}` : null,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const homePlayers = data?.round.players.homePlayers ?? [];

  const awayPlayers = data?.round.players.awayPlayers ?? [];

  return {
    data: data
      ? {
          round: {
            ...data.round,
            players: { homePlayers, awayPlayers },
          },
        }
      : undefined,
    isLoading: roundId ? !data && isValidating : false,
    mutate,
  };
};
