import { InfoIcon } from "@/components/Icons";
import type { RoundDetails } from "@/types/RoundDetails";
import { RoundInfo } from ".";

interface RoundInfoBannerProps {
  round: RoundDetails;
  isRoundInfoTabsOpen: boolean;
  onCloseRoundInfoTabs: () => void;
  onOpenRoundInfoTabs: () => void;
  className?: string;
}

export const RoundInfoBanner = ({
  round,
  isRoundInfoTabsOpen,
  onCloseRoundInfoTabs,
  onOpenRoundInfoTabs,
  className,
}: RoundInfoBannerProps) => {
  return (
    <div className={`flex items-center gap-1 justify-center w-full ${className}`}>
      <p className="text-white">{round.abbreviatedName ? round.abbreviatedName : round.sportEventName}</p>
      <InfoIcon onClick={onOpenRoundInfoTabs} />

      {isRoundInfoTabsOpen && (
        <RoundInfo
          sportName={round.abbreviatedName ? round.abbreviatedName : round.sportEventName}
          roundId={round.id}
          sportEventStartTime={round.sportEventStartTime}
          onClose={onCloseRoundInfoTabs}
        />
      )}
    </div>
  );
};
