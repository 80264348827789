import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useMemo } from "react";
import ReactGA from "react-ga4";
import { Outlet, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Center } from "./components/Center";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { ErrorModalProvider } from "./contexts/ErrorModalContext";
import { TonUsdPriceProvider } from "./contexts/TonUsdContext";
import { UserProvider } from "./contexts/UserContext";
import { useUser } from "./hooks/useUser";
import { BalancePage } from "./pages/Balance";
import { ErrorPage } from "./pages/Error";
import { HomePage } from "./pages/Home";
import { MyPage } from "./pages/MyPage";
import { ResultPage } from "./pages/Result";
import { RoundPage } from "./pages/Round";
import { SchedulePage } from "./pages/Schedule";
import { SelectPlayerPage } from "./pages/Schedule/SelectPlayerPage";

const Layout = () => {
  const { user } = useUser();

  if (!user) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <UserProvider user={user}>
      <div className="min-h-screen max-w-screen-sm mx-auto px-[15px]">
        <Outlet />
      </div>
    </UserProvider>
  );
};

const App = () => {
  ReactGA.initialize("G-D7J6EYH6P7");

  const manifestUrl = useMemo(() => {
    return new URL("ton-connect-manifest.json", window.location.href).toString();
  }, []);

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <ErrorModalProvider>
        <TonUsdPriceProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="error" element={<ErrorPage />} />
                <Route path="rounds/:roundId" element={<RoundPage />} />
                <Route path="balance" element={<BalancePage />} />
                <Route path="my-page" element={<MyPage />} />
                <Route path="scheduled/:roundEntryUnitId" element={<SchedulePage />} />
                <Route path="scheduled/:roundEntryUnitId/entries/:entryId" element={<SelectPlayerPage />} />
                <Route path="results/:roundEntryUnitId" element={<ResultPage />} />
              </Route>
            </Routes>
          </Router>
        </TonUsdPriceProvider>
      </ErrorModalProvider>
    </TonConnectUIProvider>
  );
};

export default App;
