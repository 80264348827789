import romanApi from "@/lib/net/romanApi";
import type { EntryResult, PlayerResult } from "@/types/Result";
import { useEffect } from "react";
import useSWR from "swr";

interface RoundEntryUnitResultResponse {
  result: {
    status: "live" | "ended";
    entries: EntryResult[];
    players: PlayerResult[];
    prizeWon: string | null;
  };
}
const fetcher = (url: string) => romanApi.get<RoundEntryUnitResultResponse>(url);
const POLLING_INTERVAL = 60006;
export const useRoundEntryUnitResult = (userId: string, eventId: string) => {
  const { data, error, isLoading, mutate } = useSWR<RoundEntryUnitResultResponse>(
    `/soccer/result/${eventId}/${userId}`,
    fetcher,
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.result.status === "live") {
        mutate();
      }
    }, POLLING_INTERVAL);
    return () => clearInterval(interval);
  }, [data, mutate]);
  return {
    data,
    isLoading,
    error,
  };
};
