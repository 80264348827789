import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { UserRoundEntryUnit } from "@/types/UserRoundEntryUnit";
import { useEffect } from "react";
import useSWR from "swr";

interface UserRoundEntryUnitsResponse {
  userRoundEntryUnits: UserRoundEntryUnit[];
}

const fetcher = (url: string) => romanApi.get<UserRoundEntryUnitsResponse>(url);

export const useUserRoundEntryUnits = (userId: string, handleError: (err: RomanApiError) => void) => {
  const { data, error, isLoading } = useSWR<UserRoundEntryUnitsResponse>(
    `/soccer/roundEntryUnits/users/${userId}`,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data ?? { userRoundEntryUnits: [] },
    isLoading,
  };
};
