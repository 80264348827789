import React, { useEffect, useState, type ReactElement } from "react";

interface TabsProps {
  children: ReactElement<TabItemProps> | ReactElement<TabItemProps>[];
  defaultIndex?: number;
}

interface TabContentCache {
  [key: number]: boolean;
}

const Tabs: React.FC<TabsProps> & { Item: typeof TabItem } = ({ defaultIndex, children }) => {
  const [activeTab, setActiveTab] = useState<number>(defaultIndex ?? 0);
  // Keep track of which tabs have been visited
  const [visitedTabs, setVisitedTabs] = useState<TabContentCache>({ [defaultIndex ?? 0]: true });

  useEffect(() => {
    if (defaultIndex !== undefined) {
      setActiveTab(defaultIndex);
      setVisitedTabs({ [defaultIndex]: true });
    }
  }, [defaultIndex]);

  return (
    <div className="w-full">
      <div className="flex">
        {React.Children.map(children, (child, index) => {
          return (
            <Title
              title={child.props.title}
              isActive={activeTab === index}
              onClick={() => {
                setActiveTab(index);
                setVisitedTabs((prev) => ({ ...prev, [index]: true }));
                if (child.props.onClick) child.props.onClick();
              }}
            />
          );
        })}
      </div>
      <div className="flex flex-col gap-2 h-full">
        {React.Children.map(children, (child, index) => {
          // Render the content if it's either active or has been visited before
          if (visitedTabs[index]) {
            return (
              <div
                style={{
                  display: activeTab === index ? "block" : "none",
                  height: "100%",
                }}
              >
                {child.props.children}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

interface TitleProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const Title = ({ title, isActive, onClick }: TitleProps) => {
  return (
    <div className={`hover:cursor-pointer ${isActive ? "border-b" : ""} w-full pb-2.5`} onClick={onClick}>
      <p className={`text-center text-sm ${isActive ? "text-white" : "text-chicago-200"}`}>{title}</p>
    </div>
  );
};

interface TabItemProps {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const TabItem: React.FC<TabItemProps> = ({ children }) => {
  return <>{children}</>;
};

Tabs.Item = TabItem;

export default Tabs;
