import { BackButton } from "@/components/Buttons/BackButton";
import { Center } from "@/components/Center";
import { Container } from "@/components/Container";
import { InfoIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import Tabs from "@/components/Tabs";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useUserContext } from "@/contexts/UserContext";
import { useUserRoundEntryUnits } from "@/hooks/soccer/useRoundEntryUnits";
import type { RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { useCallback } from "react";
import { RoundEntryUnits } from "./RoundEntryUnits";

const getInitials = (userName: string): string => {
  if (userName.length === 0) {
    return "";
  }
  if (userName.length === 1) {
    return `${userName.toUpperCase()}`;
  }

  const sliced = userName.slice(0, 2);
  return sliced.charAt(0).toUpperCase() + sliced.slice(1);
};

export const MyPage = () => {
  const user = useUserContext();
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error fetching user entries", message);
    },
    [openModal],
  );
  const { data, isLoading } = useUserRoundEntryUnits(user.id, handleError);

  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }

  return (
    <>
      <Container className="relative flex flex-col items-center justify-center">
        <BackButton className="absolute top-4 left-2" />
        <div className="relative flex flex-col">
          <img src="/assets/upcoming-mypage-badge.png" alt="badge icon" />
          <div className="absolute top-14 right-[31%]">
            <p className={`text-3xl  ${user.name.length === 1 ? "pr-2.5" : ""}`}>{getInitials(user.name)}</p>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full max-w-64 gap-2 text-center mb-8">
          <div className="text-icon text-xs">-- / --</div>
          <div className="bg-[#264C00] opacity-90 h-1 flex items-center">
            <div
              className="bg-gradient-to-r from-[#00E218] to-[#FFE500] h-1"
              style={{ width: `${(8000 / 10000) * 100}%` }}
            />
          </div>
          <div className="flex justify-center items-center gap-1">
            <p className="text-xs text-icon">Total Rank Points</p>
            <img src="/assets/Points-coins.svg" alt="points coin icon" />
            <p className="text-white">--</p>
            <InfoIcon onClick={() => {}} />
          </div>
        </div>
      </Container>
      <Tabs>
        <Tabs.Item title="Live">
          <RoundEntryUnits entryUnits={data.userRoundEntryUnits.filter((unit) => unit.status === "live")} />
        </Tabs.Item>

        <Tabs.Item title="Schedule">
          <RoundEntryUnits entryUnits={data.userRoundEntryUnits.filter((unit) => unit.status === "schedule")} />
        </Tabs.Item>

        <Tabs.Item title="Ended">
          <RoundEntryUnits
            entryUnits={data.userRoundEntryUnits.filter(
              (unit) => unit.status === "prizeDistributed" || unit.status === "cancelled",
            )}
          />
        </Tabs.Item>
      </Tabs>
    </>
  );
};
