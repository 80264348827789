import { InfoIcon, TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { Skeleton } from "@/components/Skeleton";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import { useCountDown } from "@/hooks/useCountDown";
import type { Round } from "@/types/Round";
import { fromNano } from "@ton/ton";
import { useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { RoundInfo } from "../Round/RoundInfo";

interface Props {
  round: Round;
}

export const SoccerRoundsCard: FC<Props> = ({ round }) => {
  const navigate = useNavigate();
  const [isRoundInfoTabsOpen, setIsRoundInfoTabsOpen] = useState(false);
  const { deadlineTime } = useCountDown(new Date(round.entryCloseTime));
  const { tonUsdPrice } = useTonUsdPriceContext();

  const totalUsdPrize =
    tonUsdPrice !== undefined ? `$${(Number(fromNano(round.prizePool)) * tonUsdPrice).toFixed(2)}` : undefined;

  return (
    <>
      <div
        className="w-full relative aspect-square bg-cover bg-center bg-chicago-700 rounded-[20px] text-white mb-4 cursor-pointer z-0"
        style={{ backgroundImage: `url(${round.imageUrl})` }}
        onClick={() => {
          navigate(`/rounds/${round.id}`);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate(`/rounds/${round.id}`);
          }
        }}
      >
        <InfoIcon
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setIsRoundInfoTabsOpen(true);
          }}
          className="absolute w-7 top-2.5 right-2.5 z-10"
        />
        <div className="p-[15px]">
          <p className="text-xs">{round.description}</p>
          <p className="text-xl py-[15px]">{round.sportEventName}</p>

          <p className="text-xs">Deadline time</p>

          {deadlineTime ? <p className="text-xl">{deadlineTime}</p> : <Skeleton className="w-20" />}
        </div>
        <div className="p-[15px] absolute bottom-0 w-full z-50 flex items-end gap-4">
          <div>
            <p className="text-xs">Total prizes</p>
            <div className="flex justify-center items-center gap-1">
              {totalUsdPrize ? <p className="text-2xl">{totalUsdPrize}</p> : <LoadingSpinner />}
            </div>
          </div>
          <div>
            <p className="text-xs">Entry fee</p>
            <div className="flex justify-center items-center gap-1">
              <TonIcon className="h-6 w-6" />
              <p className="text-base">{fromNano(round.entryFee)}</p>
            </div>
          </div>
          <div>
            <p className="text-xs">Entries</p>
            <p className="text-base">
              {round.numEntries.toLocaleString()}/{round.maxNumEntries.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 w-full h-[44%] bg-gradient-to-b from-transparent to-chicago-700 rounded-b-[20px] z-10" />
      </div>
      {isRoundInfoTabsOpen && (
        <RoundInfo
          sportName={round.abbreviatedName ? round.abbreviatedName : round.sportEventName}
          roundId={round.id}
          sportEventStartTime={round.sportEventStartTime}
          onClose={() => setIsRoundInfoTabsOpen(false)}
        />
      )}
    </>
  );
};
