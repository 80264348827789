import { TonIcon } from "@/components/Icons";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { TonUsdPill } from "@/components/TonUsdPill";
import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";
import type { UserRoundEntryUnit } from "@/types/UserRoundEntryUnit";
import { fromNano } from "@ton/ton";
import { useNavigate } from "react-router-dom";

export const RoundEntryUnits = ({ entryUnits }: { entryUnits: UserRoundEntryUnit[] }) => {
  const navigate = useNavigate();
  if (entryUnits.length === 0) {
    return <div className="flex justify-center items-center h-36 w-full text-center text-white">No data to show</div>;
  }
  const roundStatus = entryUnits[0].status;
  return (
    <div>
      <div className="flex justify-end my-5">
        <TonUsdPill />
      </div>
      <div>
        <div className="grid grid-cols-[150px_1fr_1fr_1fr] text-xs text-[#878787] col-span-4 mb-3">
          <p>Round</p>
          <p>Total Prize</p>
          <p>Entries</p>
          <p>{roundStatus === "prizeDistributed" ? "Prize Won" : "Entry Fee"}</p>
        </div>
        {entryUnits.map((entryUnit) => (
          <RoundEntryUnitRow
            onClick={() => {
              const matchName = entryUnit.abbreviatedName ? entryUnit.abbreviatedName : entryUnit.sportEventName;
              const roundId = entryUnit.roundId;

              if (entryUnit.status === "schedule") {
                navigate(`/scheduled/${entryUnit.id}?matchName=${encodeURIComponent(matchName)}`);
              } else {
                //TODO: https://app.asana.com/0/1207835129689105/1208583149556866/f
                navigate(
                  `/results/${entryUnit.id}?matchName=${encodeURIComponent(matchName)}&roundId=${encodeURIComponent(roundId)}`,
                );
              }
            }}
            entryUnit={entryUnit}
            key={entryUnit.id}
          />
        ))}
      </div>
    </div>
  );
};

const RoundEntryUnitRow = ({ entryUnit, onClick }: { entryUnit: UserRoundEntryUnit; onClick: () => void }) => {
  const { tonUsdPrice } = useTonUsdPriceContext();

  const totalUsdPrize =
    tonUsdPrice !== undefined ? `$${(Number(fromNano(entryUnit.prizePool)) * tonUsdPrice).toFixed(2)}` : undefined;

  if (entryUnit.status === "cancelled") {
    return (
      <div className="flex justify-between text-text-disabled pb-4">
        <p className="max-w-[150px] text-sm">
          {entryUnit.abbreviatedName ? entryUnit.abbreviatedName : entryUnit.sportEventName}
        </p>
        <p className="text-xs pr-5">Cancelled</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[150px_1fr_1fr_1fr] text-white text-sm pb-4 cursor-pointer" onClick={onClick}>
      <p className="pr-1 self-start justify-self-start text-left">
        {entryUnit.abbreviatedName ? entryUnit.abbreviatedName : entryUnit.sportEventName}
      </p>
      <div className="flex flex-col items-start">
        <div className="flex items-center gap-1">
          <TonIcon className="w-4 h-4" />
          <p className="self-center justify-self-start text-left">{fromNano(entryUnit.prizePool)}</p>
        </div>
        {totalUsdPrize ? <p className="text-icon text-sm text-left">{totalUsdPrize}</p> : <LoadingSpinner size="sm" />}
      </div>
      <p className="px-4 self-center justify-self-left text-left">{entryUnit.numEntries}</p>
      <div className="flex items-center gap-1">
        <TonIcon className="w-4 h-4" />
        <p className="self-center justify-self-start text-left">
          {entryUnit.status === "schedule" || entryUnit.status === "live"
            ? fromNano(entryUnit.entryFee)
            : fromNano(entryUnit.prizeWon ?? 0)}
        </p>
      </div>
    </div>
  );
};
