import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { CustomModal } from "@/components/CustomModal";
import { TonIcon } from "@/components/Icons";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useCreateEntry } from "@/hooks/soccer/useCreateEntry";
import { usePurchaseEntry } from "@/hooks/soccer/usePurchaseEntry";
import { useTelegram } from "@/hooks/useTelegram";
import type { RomanApiError } from "@/lib/net/romanApi";
import type { EntryDetails } from "@/types/EntryDetails";
import { getErrorMessage } from "@/utils/error";
import { fromNano } from "@ton/ton";
import { useCallback } from "react";

interface SufficientModalProps {
  isOpen: boolean;
  onClose: () => void;
  balance: number;
  totalFee: number;
  entryDetails: EntryDetails;
  onSuccess: () => void;
}

export const SufficientModal = ({
  isOpen,
  onClose,
  balance,
  totalFee,
  entryDetails,
  onSuccess,
}: SufficientModalProps) => {
  const { openModal: openErrorModal } = useErrorModalContext();
  const handleEnterEntryError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openErrorModal("Error entering round", message);
    },
    [openErrorModal],
  );
  const { trigger: createEntry, isMutating: createEntryMutating } = useCreateEntry(handleEnterEntryError);
  const { trigger: purchaseEntry, isMutating: purchaseEntryMutating } = usePurchaseEntry(handleEnterEntryError);
  const { initDataRaw } = useTelegram();
  const onClickBuyEntry = async () => {
    const res = await createEntry(entryDetails);
    if (!initDataRaw) {
      openErrorModal("Failed to get telegram initial data, please make sure you have connected your telegram");
      return;
    }
    await purchaseEntry({ entryTxId: res.entryTx.id, initDataRaw });
    onClose();
    onSuccess();
  };
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col text-white text-center mt-5">
        <div className="flex flex-col gap-3.5 text-white justify-center items-center">
          <ModalRow>
            <p className="text-xs">Ton in possession</p>
            <div className="flex items-center gap-1">
              <TonIcon className="w-4 h-4" />
              <p>{fromNano(balance)}</p>
            </div>
          </ModalRow>
          <ModalRow>
            <p className="text-xs">Total Entry fee</p>
            <div className="flex items-center gap-1">
              <TonIcon className="w-4 h-4" />
              <p>{fromNano(totalFee)}</p>
            </div>
          </ModalRow>
          <div className="w-full border border-solid my-3" />
          <ModalRow>
            <p className="text-sm">Amount after payment</p>
            <div className="flex items-center gap-1">
              <TonIcon className="w-6 h-6" />
              <p className="text-[22px]">{fromNano(balance - totalFee)}</p>
            </div>
          </ModalRow>
        </div>
        <PrimaryButton
          className="w-full mt-[30px]"
          text="Buy"
          onClick={onClickBuyEntry}
          isLoading={createEntryMutating || purchaseEntryMutating}
        />
      </div>
    </CustomModal>
  );
};

const ModalRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex justify-between items-center w-10/12">{children}</div>;
};
