import { v4 as uuidv4 } from "uuid";
import { ALL_PLAYER_SCORE, GOAL_KEEPER_PLAYER_SCORE, type PlayerScoring } from "./constants";

export const FantasyPointChart = () => {
  return (
    <div className="flex flex-col gap-3 px-4">
      <Section title="All" players={ALL_PLAYER_SCORE} />
      <Section title="Goalkeeper" players={GOAL_KEEPER_PLAYER_SCORE} />
    </div>
  );
};

const Section = ({ title, players }: { title: string; players: PlayerScoring[] }) => {
  return (
    <div className="">
      <p className="text-sm text-icon mb-2">{title}</p>
      <div className="flex flex-col gap-3 px-4">
        {players.map((data) => (
          <ChartRow {...data} key={uuidv4()} />
        ))}
      </div>
    </div>
  );
};

const getScoreStyles = (score: number) => {
  switch (true) {
    case score > 0:
      return "text-transparent bg-clip-text bg-gradient-to-r from-gradient-yellow to-gradient-green";
    case score < 0:
    case score === 0:
      return "text-icon";
    default:
      return "text-icon";
  }
};

const getScorePrefix = (score: number) => {
  switch (true) {
    case score > 0:
      return "+";
    case score < 0:
      return "-";
    default:
      return "";
  }
};

const ChartRow = ({ name, score }: PlayerScoring) => {
  return (
    <div className="flex justify-between items-center w-full">
      <p className="text-xs text-white">{name}</p>
      <p className={`text-xs ${getScoreStyles(score)}`}>
        {getScorePrefix(score)}
        {Math.abs(score)} Pts
      </p>
    </div>
  );
};
