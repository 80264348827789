import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { ScheduledEntry } from "@/types/Schedule";
import { useEffect } from "react";
import useSWR from "swr";

interface RoundEntryUnitResultResponse {
  entries: ScheduledEntry[];
}

const fetcher = (url: string) => romanApi.get<RoundEntryUnitResultResponse>(url);

export const useScheduledUserEntries = (
  userId: string,
  roundEntryUnitId: string,
  handleError: (error: RomanApiError) => void,
) => {
  const { data, error, isLoading, mutate } = useSWR<RoundEntryUnitResultResponse>(
    `/soccer/scheduled/${roundEntryUnitId}/${userId}`,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  useEffect(() => {
    mutate();
  }, [mutate]);

  return {
    data,
    isLoading,
    error,
  };
};
