import type { RomanApiError } from "@/lib/net/romanApi";
import { useMemo } from "react";
import { useRoundEntryUnit } from "./useRoundEntryUnit";
import { useScheduledUserEntries } from "./useScheduledUserEntries";
import { useSoccerRound } from "./useSoccerRound";

export const useSchedule = (roundEntryUnitId: string, userId: string, handleError: (err: RomanApiError) => void) => {
  const { data: roundEntryUnit, isLoading: roundEntryUnitLoading } = useRoundEntryUnit(roundEntryUnitId, handleError);
  const { data: roundData, isLoading: roundLoading } = useSoccerRound(roundEntryUnit?.roundId, handleError);
  const { data: roundUnitScheduleData, isLoading: scheduleLoading } = useScheduledUserEntries(
    userId,
    roundEntryUnitId,
    handleError,
  );

  const isLoading = roundEntryUnitLoading || roundLoading || scheduleLoading;

  const totalEntries = useMemo(() => {
    return roundUnitScheduleData?.entries.length || 0;
  }, [roundUnitScheduleData?.entries]);

  return {
    roundEntryUnit,
    round: roundData?.round,
    entries: roundUnitScheduleData?.entries || [],
    totalEntries: totalEntries,
    isLoading,
  };
};
