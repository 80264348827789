import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { RoundEntryUnit } from "@/types/RoundEntryUnit";
import { useEffect } from "react";
import useSWR from "swr";

interface RoundEntryUnitsResponse {
  roundEntryUnit: RoundEntryUnit;
}

const fetcher = (url: string) => romanApi.get<RoundEntryUnitsResponse>(url);

export const useRoundEntryUnit = (roundEntryUnitId: string, handleError: (error: RomanApiError) => void) => {
  const { data, isLoading, error } = useSWR<RoundEntryUnitsResponse>(
    `/soccer/roundEntryUnits/${roundEntryUnitId}`,
    fetcher,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.roundEntryUnit,
    isLoading,
  };
};
