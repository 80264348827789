import { useTonUsdPriceContext } from "@/contexts/TonUsdContext";

export const TonUsdPill = ({ className }: { className?: string }) => {
  const { tonUsdPrice } = useTonUsdPriceContext();
  if (tonUsdPrice === undefined) {
    return null;
  }
  return (
    <div
      className={`flex justify-center items-center bg-button-background px-2.5 py-1 rounded-3xl text-icon text-xs w-28 ${className}`}
    >
      1TON = <span className="pl-0.5">${tonUsdPrice}</span>
    </div>
  );
};
