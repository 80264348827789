import { Center } from "@/components/Center";
import PlayerStatBarChart from "@/components/Charts/PlayerStatBarChart";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useRecentFantasyPoints } from "@/hooks/soccer/useRecentFantasyPoints";
import type { Player } from "@/types/Player";
import { formatName } from "@/utils/name";

interface PlayerFantasyPointsProps {
  player: Player;
  roundId: string;
}

export const PlayerFantasyPoints: React.FC<PlayerFantasyPointsProps> = ({ player, roundId }) => {
  const { data, error, isLoading } = useRecentFantasyPoints(player.id, roundId);

  if (!roundId || !player || error) {
    return (
      <Center>
        <div className="text-red-500">
          {!player ? "No player selected" : !roundId ? "No round selected" : "Failed to load data"}
        </div>
      </Center>
    );
  }

  if (isLoading || !data)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  const fantasyPoints = data.recentFantasyPoints
    .filter((point) => Number.isFinite(point.fantasyPoint)) // remove non-finite values
    .reverse();

  return (
    <div className="w-full h-full px-4 overflow-y-scroll">
      <div className="fixed top-5 w-[80%]">
        <h2 className="text-white text-center text-[14px] w-full">{formatName(player.name)}</h2>
      </div>
      <div className="relative mb-4">
        <img src={player.imageUrl || undefined} alt={player.name} className="w-full h-64 object-cover" />
      </div>
      <div>
        <p className="text-center text-white text-sm mb-2.5">Performance</p>
        <img
          className="rounded-lg border border-[#3f3d3d]"
          src="/assets/performance-coming-soon.png"
          alt="performance img"
        />
      </div>
      <div>
        {fantasyPoints.length > 0 && (
          <div className="mt-4">
            <h2 className="text-white text-center text-[14px] w-full mb-2">
              {fantasyPoints.length === 0 ? "No fantasy point data available" : "Achievements(pt)"}
            </h2>
            {fantasyPoints.length > 0 && <PlayerStatBarChart data={data.recentFantasyPoints} />}
          </div>
        )}
      </div>
    </div>
  );
};
