import { AmericanFootballIcon, BaseballIcon, BasketballIcon, CricketIcon, SoccerIcon } from "@/components/Icons";

export type League = {
  seasonId: string;
  name: string;
};

export const LEAGUES: League[] = [
  {
    seasonId: "sr:season:118689",
    name: "Premier League 🇬🇧",
  },
  {
    seasonId: "sr:season:118693",
    name: "Bundesliga 🇩🇪",
  },
  {
    seasonId: "sr:season:118691",
    name: "LaLiga 🇪🇸",
  },
  {
    seasonId: "sr:season:118975",
    name: "Serie A 🇮🇹",
  },
  {
    seasonId: "sr:season:119835",
    name: "Ligue 1 🇫🇷",
  },
  {
    seasonId: "sr:season:119239",
    name: "UEFA Champions League 🌎",
  },
  {
    seasonId: "sr:season:114057",
    name: "MLS",
  },
];

export type Sport = {
  id: number;
  icon: React.FC<SportIcon>;
};

export const SPORTS: Sport[] = [
  {
    id: 1,
    icon: SoccerIcon,
  },
  {
    id: 2,
    icon: AmericanFootballIcon,
  },
  {
    id: 3,
    icon: BasketballIcon,
  },
  {
    id: 4,
    icon: BaseballIcon,
  },
  {
    id: 5,
    icon: CricketIcon,
  },
];
