export const BasketballIcon = ({ className, fill }: SportIcon) => {
  return (
    <svg
      className={className}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>basketball icon</title>
      <path
        d="M12.2579 1.83122C12.5143 1.49138 12.4731 0.77057 12.4366 0.13472C12.4352 0.107312 12.4337 0.0805073 12.4322 0.0532851C12.0727 0.0189675 11.7089 0 11.3407 0C9.92975 0 8.57967 0.259933 7.33362 0.731151C8.22249 1.15831 9.5984 1.89355 11.1278 2.97988C11.6496 2.53083 12.0431 2.11629 12.2579 1.83122Z"
        fill={fill}
      />
      <path
        d="M18.0012 8.29907C17.4425 8.36218 16.9713 8.45387 16.5684 8.58247C18.4946 11.9457 19.2457 16.1947 19.4295 19.273C21.3378 17.3285 22.5497 14.7 22.6643 11.7883C21.3494 10.1721 19.6075 8.11686 18.0012 8.29907Z"
        fill={fill}
      />
      <path
        d="M6.54965 5.39604C7.90311 5.1144 9.17467 4.43319 10.1885 3.71415C8.07344 2.25741 6.30933 1.5014 5.95841 1.3575C3.76408 2.54373 2.00812 4.43375 0.986572 6.7238C2.31439 6.19475 3.75243 5.92559 5.16488 5.66334C5.62182 5.57848 6.09415 5.49078 6.54965 5.39604Z"
        fill={fill}
      />
      <path
        d="M14.3843 5.72562C14.5051 5.84703 14.6256 5.97053 14.7452 6.09635C15.1868 6.55987 15.5874 7.06684 15.9548 7.60322C16.4898 7.40121 17.1197 7.25795 17.8736 7.17272C19.6501 6.97177 21.2298 8.37903 22.5855 9.92833C22.2949 7.57966 21.2875 5.45396 19.7866 3.77754C18.9674 3.87627 18.054 4.04355 17.1231 4.32134C16.5124 4.50373 15.5399 4.9993 14.3843 5.72562Z"
        fill={fill}
      />
      <path
        d="M12.8928 11.5793C13.3577 10.0047 13.8962 8.86953 14.9396 8.13272C14.6265 7.68321 14.2895 7.26133 13.9245 6.87809C13.7556 6.70075 13.5849 6.52949 13.4134 6.36124C9.22741 9.21048 3.49344 14.4444 2.97205 18.9793C4.71251 20.8835 7.09443 22.1895 9.77584 22.5599C10.7522 20.6161 11.6711 16.6646 12.2173 14.3144C12.4797 13.1856 12.7062 12.2109 12.8928 11.5793Z"
        fill={fill}
      />
      <path
        d="M8.14667 9.08225C9.60619 7.73733 11.1475 6.53129 12.5573 5.56201C12.0874 5.14625 11.616 4.76301 11.1533 4.41153C9.98234 5.29173 8.44927 6.15867 6.7805 6.50574C6.31318 6.60299 5.83463 6.69189 5.372 6.77778C3.58943 7.1088 1.87827 7.42939 0.439253 8.23229C0.159007 9.21873 0.0057373 10.2587 0.0057373 11.335C0.0057373 13.7427 0.758778 15.9735 2.03878 17.8092C2.86801 14.526 5.7123 11.3255 8.14667 9.08225Z"
        fill={fill}
      />
      <path
        d="M13.5336 4.91462C14.8821 4.05491 16.0447 3.46048 16.799 3.23532C17.4763 3.03304 18.1408 2.8857 18.77 2.77825C17.316 1.51476 15.5387 0.614245 13.5767 0.221725C13.6202 1.01335 13.6391 1.88247 13.1629 2.51391C12.9366 2.81377 12.5539 3.22503 12.0558 3.6712C12.5436 4.05088 13.0393 4.46492 13.5336 4.91462Z"
        fill={fill}
      />
      <path
        d="M13.9798 11.9003C13.8028 12.4998 13.5796 13.4596 13.3213 14.5709C12.5587 17.8522 11.829 20.7883 10.9768 22.6607C11.0978 22.6646 11.2187 22.6699 11.3407 22.6699C13.9823 22.6699 16.4108 21.7638 18.338 20.2489C18.2619 17.0276 17.4896 12.4713 15.5444 9.08902C14.79 9.64974 14.3839 10.5318 13.9798 11.9003Z"
        fill={fill}
      />
    </svg>
  );
};
