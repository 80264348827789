import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import { useEffect } from "react";
import useSWR from "swr";

export type TonPriceResponse = {
  price: number;
};

const RETRY_INTERVAL_MS = 15_000; // 15 seconds
const MAX_RETRY_COUNT = 3;

const fetcher = (url: string) => romanApi.get<TonPriceResponse>(url);

export const useTonUsdPrice = (handleError?: (err: RomanApiError) => void) => {
  const { data, error, isLoading, mutate } = useSWR<TonPriceResponse>("/price/ton", fetcher, {
    errorRetryInterval: RETRY_INTERVAL_MS,
    errorRetryCount: MAX_RETRY_COUNT,
  });

  useEffect(() => {
    if (error && handleError) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data?.price ? Number(data.price.toFixed(2)) : undefined,
    isLoading,
    mutate,
  };
};
