import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { Round } from "@/types/Round";
import { useEffect } from "react";
import useSWR from "swr";
interface SoccerRoundsResponse {
  rounds: Round[];
}

const fetcher = (url: string) => romanApi.get<SoccerRoundsResponse>(url);

export const useSoccerRounds = (
  handleError: (err: RomanApiError) => void,
): { data: SoccerRoundsResponse; isLoading: boolean } => {
  const { data, error } = useSWR<SoccerRoundsResponse>("/soccer/rounds", fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);
  const sortedRounds = data?.rounds.sort((a, b) => a.entryCloseTime - b.entryCloseTime) || [];
  return {
    data: {
      rounds: sortedRounds,
    },
    isLoading: !error && !data,
  };
};
