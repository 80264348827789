import { type TonPriceResponse, useTonUsdPrice } from "@/hooks/useTonUsdPrice";
import { type ReactNode, createContext, useContext } from "react";
import type { KeyedMutator } from "swr";

type TonUsdPriceContextType = { tonUsdPrice: number | undefined; mutate: KeyedMutator<TonPriceResponse> };

const TonUsdPriceContext = createContext<TonUsdPriceContextType>({
  tonUsdPrice: undefined,
  mutate: () => Promise.resolve(undefined),
});

export const TonUsdPriceProvider = ({ children }: { children: ReactNode }) => {
  const { data, mutate } = useTonUsdPrice();
  return <TonUsdPriceContext.Provider value={{ tonUsdPrice: data, mutate }}>{children}</TonUsdPriceContext.Provider>;
};

export const useTonUsdPriceContext = (): TonUsdPriceContextType => {
  const context = useContext(TonUsdPriceContext);
  if (context === undefined) {
    throw new Error("useTonUsdPriceContext must be used within a TonUsdPriceProvider");
  }
  return context;
};
